@import url('https://constellation-static.web.vanguard.com/v0/fonts/constellation-fonts.css');
@import '@vg-constellation/styles/styles';
@import '@vg-constellation/styles/typography';
@import "@vg-constellation/tokens/tokens";

body,
html {
  margin: 0;
  padding: 0;
  font-family: $c11n-font-family-sans;
}

.vgn-skip-navigation,
.vgn-skip-navigation:focus,
.vgn-skip-navigation:active {
  display: none !important;
}

#app-skipnav {
  position: absolute;
  overflow: hidden;
  top: -999px;
}

#app-skipnav:focus,
#app-skipnav:active {
  background-color: white;
  color: black;
  height: auto;
  left: 4px;
  padding: 6px 8px;
  top: 4px;
  width: auto;
  outline: 2px solid $focus-color;
  z-index: 999;
  border: none;
}

#app-main-content {
  outline: none;
}

@media print {
  main {
    .page {
      .container,
      .container-fluid,
      .container-xs,
      .container-sm,
      .container-md,
      .container-lg,
      .container-xl,
      .container-xxl {
        min-width: 900px;
      }
    }
  }

  vg-vgn-header {
    display: none;
  }

  .greeting-container {
    background-image: none !important;
  }

  .c11n-accordion .c11n-accordion__heading-group {
    display: flex !important;
    flex-direction: row !important;
  }

  .c11n-accordion.c11n-accordion--summary .c11n-accordion__content {
    min-width: 0 !important;
  }

  #vgn-globalFooter {
    display: none;
  }

  .c11n-accordion__icon {
    display: inline-block;
  }

  .c11n-accordion__heading,
  .c11n-accordion__content {
    font-size: 20px !important;
  }

  #kampyleButtonContainer {
    display: none;
  }
}
